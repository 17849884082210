import './index.scss'

let hamburger = document.querySelector('#hamburger')
let header = document.querySelector('.header')

hamburger.addEventListener('click', () => header.classList.toggle('active'))

// const delivery_hint_close = document.querySelector('.delivery_hint_close');
// delivery_hint_close.addEventListener("click", () => {

//     document.querySelector('.header_container').classList.add('delivery_hint_hidden')
// });