import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
import 'swiper/swiper-bundle.css'
import './index.scss'

Swiper.use([Pagination, Navigation])


if (document.querySelector(".top_slider_box")) {
    let swiper_top = new Swiper(".top_slider_box", {
        slidesPerView: '1',

        pagination: {
            el: '.pagination_1',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<div class="pagination_wrapper"> 0<span class="' + currentClass + '"></span>' + '<span> ― </span>' +
                    '0<span class="' + totalClass + '"></span> </div>'
            }
        },
    })
}

if (document.querySelector(".services")) {
    let swiper_services = new Swiper(".services", {
        breakpoints: {
            800: {
                slidesPerView: '3',
            },
            600: {
                slidesPerView: '2',
            },
            500: {
                slidesPerView: '1.5',
            },
            300: {
                slidesPerView: '1',
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })
}

if (document.querySelector(".warming_swiper")) {
    let swiper_services = new Swiper(".warming_swiper", {
        spaceBetween: 20,
        breakpoints: {
            800: {
                slidesPerView: '3',
            },
            600: {
                slidesPerView: '2',
            },
            500: {
                slidesPerView: '1.5',
            },
            300: {
                slidesPerView: '1',
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })
}

if (document.querySelector(".beton_bottom")) {
    let swiper_beton = new Swiper(".beton_bottom", {
        breakpoints: {
            1200: {
                slidesPerView: '4',
            },
            300: {
                slidesPerView: '2',
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })
}

if (document.querySelector(".blog_right")) {
    let swiper_blog = new Swiper(".blog_right", {
        spaceBetween: 10,

        pagination: {
            el: '.pagination_2',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '0<span class="' + currentClass + '"></span>' + '<span> ― </span>' +
                    '0<span class="' + totalClass + '"></span>'
            }
        },

        breakpoints: {
            1200: {
                slidesPerView: '3.5',
            },
            800: {
                slidesPerView: '2.5',
            },
            600: {
                slidesPerView: '2',
            },
            300: {
                slidesPerView: '1.5',
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })
}