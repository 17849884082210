import './abouts.scss'
import './beton.scss'
import './blog.scss'
import './storage.scss'
import './top.scss'

const width = document.documentElement.offsetWidth

const blog_link = document.querySelector('.blog_link')
const pagination_2 = document.querySelector('.pagination_2')

const blog_bottom = document.querySelector('.blog_bottom')

const categories_wrapper = document.querySelectorAll('[data-category_id]')

if (width <= 430) {
    blog_bottom.append(pagination_2)
    blog_bottom.append(blog_link)

    categories_wrapper.forEach(category => {
        const content = document.querySelector(`#content-${category.dataset.category_id}`)
        category.prepend(content)
    })
}