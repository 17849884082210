// script common elements
import '../components/module/modal_script/index'
// import '../components/module/swiper/index'
import '../components/module/form_action/index'
// import '../components/module/sliderPosts'
import '../../source/libs/custom_mask/index'
import '../components/module/swiper/index'

// script pages
import '../components/common_componentc/header/index'
import '../components/common_componentc/footer/index'
import '../components/common_componentc/services/index'


import '../components/pages/index/index'